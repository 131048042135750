module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.eqm-int.com/graphql","html":{"placeholderType":"blurred","createStaticFiles":true,"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false},"searchAndReplace":[{"search":"https://cms.eqm-int.com/wp-content/","replace":"https://equitymultiple.com/wp-content/"},{"search":"https://cms.eqm-int.com/","replace":"/"}],"type":{"MediaItem":{"createFileNodes":false,"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Post":{},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"timeout":140000,"requestConcurrency":15,"previewRequestConcurrency":2,"perPage":100,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"presets":[{"presetName":"PREVIEW_OPTIMIZATION","options":{"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"Comment":{"limit":0},"Menu":{"limit":null},"MenuItem":{"limit":null},"User":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true}}],"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"AzST5l1pqmS9j0BjmRALEfab6d7mo4MU","devKey":"kEzgs6ns5ReDvL94re0Y5HxDHEwYHOQS","trackPage":false,"delayLoadUntilActivity":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7fbc93c1a5133d83106cb9447f19b77"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
