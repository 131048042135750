import 'react-slidedown/lib/slidedown.css';
import './src/styles/main.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import '@equitymultiple/react-eui/dist/index.css';

import * as Sentry from '@sentry/gatsby';
import React from 'react';

import { DataProvider } from './src/components/DataProvider';

const sentryIgnoreList = [
  'Failed to fetch',
  'Load failed',
  'Failed to load',
  "We couldn't load",
];

export const wrapPageElement = ({ element, props }) => (
  <DataProvider value={props}>{element}</DataProvider>
);

// Handles unsupported browser page rendering.
export const onInitialClientRender = () => {
  const isIeBrowser = !!window.MSCompatibleInfo;
  if (isIeBrowser) {
    window.location.replace('/unsupportedBrowser.html');
  }
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: process.env.GATSBY_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      integrations: [Sentry.browserTracingIntegration()],
      ignoreErrors: sentryIgnoreList,
      denyUrls: [/https?:\/\/[^/]*visualwebsiteoptimizer[^/]*\/?/],
    });
  }
};

export const onRouteUpdate = () => {
  if (window.analytics)
    setTimeout(() => {
      // Logs navigation to segment
      window.analytics.page(null, document.title, null, {
        integrations: {
          impact: false,
        },
      });
    }, 100);
};
