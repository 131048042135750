import React from 'react';

interface DataContextType {
  pageContext: {
    templateName: string;
    title: string;
  };
}

const initialDataContext = {
  pageContext: {
    templateName: '',
    title: '',
  },
};

const DataContext = React.createContext<DataContextType>(initialDataContext);

interface Props {
  children: React.ReactNode;
  value: DataContextType;
}

export const DataProvider: React.FC<Props> = ({ children, value }) => {
  const { pageContext } = value;

  return (
    <DataContext.Provider
      value={{
        pageContext,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
